<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        to="/"
      >
        <!-- <vuexy-logo /> -->
        <custom-logo />

        <h2 class="brand-text text-primary ml-1">
          {{ navbarTitle }}

        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            {{ $t('Sign up here') }} 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Make your app management easy and fun') }}!
          </b-card-text>
          <b-tabs align="right">
            <b-tab
              :active="type == 'root' ? true : false"
              :title="$t('Root')"
              @click="changeType('root')"
            />
            <b-tab
              :active="type == 'agent' ? true : false"
              :title="$t('Agent')"
              @click="changeType('agent')"
            />
          </b-tabs>
          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <!-- firstName -->
              <b-form-group
                :label="$t('FirstName')"
                label-for="register-firstName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="FirstName"
                  vid="firstName"
                  rules="required"
                >
                  <b-form-input
                    id="register-firstName"
                    v-model="firstName"
                    name="register-firstName"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('FirstName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- firstName -->
              <b-form-group
                :label="$t('LastName')"
                label-for="register-lastName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="LastName"
                  vid="lastName"
                  rules="required"
                >
                  <b-form-input
                    id="register-lastName"
                    v-model="lastName"
                    name="register-lastName"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('LastName')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- username -->
              <b-form-group
                :label="$t('Username')"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  vid="username"
                  rules="required"
                >
                  <b-form-input
                    id="register-username"
                    v-model="username"
                    name="register-username"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Username')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- phoneNumber -->
              <b-form-group
                :label="$t('PhoneNumber')"
                label-for="register-phoneNumber"
              >
                <validation-provider
                  #default="{ errors }"
                  name="PhoneNumber"
                  vid="phoneNumber"
                  rules="required"
                >
                  <b-form-input
                    id="register-phoneNumber"
                    v-model="tel"
                    name="register-phoneNumber"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('PhoneNumber')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- password -->
              <b-form-group
                :label="$t('Password')"
                label-for="register-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      :placeholder="$t('Password')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  {{ $t('I agree to') }}
                  <b-link v-b-modal.modal-center>
                    {{ $t('privacy policy') }} & {{ $t('terms') }}
                  </b-link>
                </b-form-checkbox>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                {{ $t('Sign up') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{ $t('Already have an account') }}?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;{{ $t('Sign in instead') }}</span>
            </b-link>
          </p>

          <!-- divider -->
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
    <!-- modal vertical center -->
    <b-modal
      id="modal-center"
      centered
      size="xl"
      :title="$t('privacy policy')+' & '+ $t('terms')"
      ok-only
      :ok-title="$t('Accept')"
    >
      <b-card-text>
        <h3>การใช้บริการของเราอาจเกี่ยวข้องกับการเก็บรวบรวมและใช้ข้อมูลของท่าน (ตามที่กำหนดไว้ในหัวข้อ “ข้อมูลที่เราเก็บรวบรวมและเราเก็บรวบรวมอย่างไร” ด้านล่างนี้) บนบริการของเรา (รวมถึงเว็บไซต์และโมบายแอพพลิเคชั่นของเรา) ทั้งนี้ เป็นเรื่องที่มีความจำเป็นที่ท่านจะต้องเข้าใจว่าเรื่องเหล่านี้ดำเนินการอย่างไรและท่านจะสามารถควบคุมได้อย่างไร ดังนั้น ขอให้ท่านกรุณาอ่านนโยบายในความเป็นส่วนตัวฉบับนี้ด้วยความระมัดระวัง</h3>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import CustomLogo from '@/Logo.vue'

import {
  BModal, VBModal, BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BTabs, BTab,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    VuexyLogo,
    CustomLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      firstName: '',
      lastName: '',
      username: '',
      password: '',
      tel: '',
      type: 'agent',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
    }
  },
  computed: {
    navbarTitle() {
      return store.state.appConfig.navbarTitle
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    changeType(type) {
      this.type = type
    },
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          useJwt.register({
            firstName: this.firstName,
            lastName: this.lastName,
            username: this.username,
            password: this.password,
            tel: this.tel,
            type: this.type,
          })
            // eslint-disable-next-line no-unused-vars
            .then(response => {
              console.log(response.data)
              const { firstName, lastName } = response.data.data.userInfo
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `${this.$t('Welcome')} ${firstName} ${lastName}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `${this.$t('You have successfully subscribed')}!`,
                },
              })
              // useJwt.setToken(response.data.accessToken)
              // useJwt.setRefreshToken(response.data.refreshToken)
              // localStorage.setItem('userData', JSON.stringify(response.data.userData))
              // this.$ability.update(response.data.userData.ability)
              // this.$router.push('/')
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'bottom-right',
                props: {
                  title: this.$t('Error'),
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: this.$t(error.response.data.message),
                },
              })
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
